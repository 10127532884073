<template>
  <div class="page">
    <div class="flex-1" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
      <div class="flex jc-between">
        <div class="flex c-gap-5 ai-center">
          <div class="bold responsive-box-child">{{ $t('legion.title') }}</div>
          <!-- <el-tooltip :disabled="infoDisabled" placement="right" effect="dark" popper-class="info-tooltip">
            <div slot="content" class="fs-md" style="font-family:FZY1JW;max-width:300px;">{{ $t('legion.infoTooltip') }}
            </div>
            <img src="../assets/account/icon-info.png" @click="infoDisabled = !infoDisabled" class="cursor hide-in-mobile"
              style="width:27px;height:28px;">
          </el-tooltip> -->
        </div>
        <!-- <div class="blue-btn flex-center align-center cursor fs-min" @click="$router.push('/legion/rules')">
          {{ $t('legion.ruleDescription') }}
        </div> -->
      </div>
      <div class="m-t-10 responsive-box c-gap-10 r-gap-10">
        <div class="flex ai-center c-gap-8 black-box responsive-box-child">
          <div>{{ $t('legion.level') }}:</div>
          <div class="bold">{{ !!legionData ? levelName(legionData.userLv) : '' }}</div>
          <div>{{ !!legionData ? levelStar(legionData.userLv) : '' }}</div>
        </div>
        <div class="flex jc-between ai-center black-box responsive-box-child">
          <div class="flex c-gap-8">
            <div>{{ $t('legion.points') }}:</div>
            <div class="bold">{{ !!legionData ? Number(legionData.userIntegral).toFixed(2) : 0 }}</div>
            <div class="black-box-received">{{ $t('legion.history.receivedMds') + Number(legionData.receivedIntegral).toFixed(2)}}MDS</div>
          </div>
          <div class="flex jc-end c-gap-10">
            <div class="flex jc-center ai-center btn btn-green" @click="getMdsBox = true">
              {{ $t('legion.receive') }}
            </div>
            <!-- <div class="flex jc-center ai-center btn" @click="$router.push('/legion/history')">
              {{ $t('legion.viewDetails') }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="m-t-20 flex jc-between">
        <div class="flex c-gap-5 ai-center">
          <div class="bold">{{ $t('legion.integralRules') }}</div>
        </div>
        <!-- <div class="blue-btn flex-center align-center cursor fs-min" @click="$router.push('/legion/ranks')">
          {{ $t('legion.pointsRankingThisMonth') }}
        </div> -->
      </div>
      <div class="m-t-10" style="color:#E6A23C;">
        {{ $t('legion.infoTooltip') }}
      </div>
      <div class="m-t-10">
        <table class="black-table" v-if="!!ruleData">
          <tr>
            <td class="bold">{{ $t('legion.headerLevel') }}</td>
            <td class="bold">{{ $t('legion.integralRules1') }}</td>
            <td class="bold">{{ $t('legion.integralRules2') }}</td>
          </tr>
          <tr v-for="item in ruleData" :key="item.level">
            <td>{{ levelName(item.level) }} {{ levelStar(item.level) }}</td>
            <td>{{ item.condition }}</td>
            <td>{{ item.integral }}</td>
          </tr>
        </table>
      </div>
      <div class="ranks">
        <div class="m-t-10 flex flex-column c-gap-20 r-gap-10" style="line-height:22px;">
          <div class="bold responsive-box-child">{{ $t('legion.ranks.title') }}</div>
          <div class="responsive-box-child flex flex-wrap ai-center c-gap-10">
            <div style="color:#E6A23C;">{{ $t('legion.ranks.subtitle') }}</div>
            <div><a :href="$t('legion.ranks.rewardsUrl')" style="color:#14E0B6;" target="_blank">[{{ $t('legion.ranks.rewardsLink') }}]</a></div>
          </div>
        </div>
        <div class="flex-1" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
          <div class="m-t-20">
            <table class="black-table bold" v-if="!!rankList" style="line-height:22px;">
              <tr>
                <td>{{ $t('legion.ranks.headerRanking') }}</td>
                <td>{{ $t('legion.ranks.headerAccount') }}</td>
                <td>{{ $t('legion.ranks.headerIntegral') }}</td>
              </tr>
              <tr v-for="item in rankList" :key="item.address">
                <td>{{ item.rank }}</td>
                <td>{{ item.address | formatAddress }} <span class="m-l-5" style="color:#14E0B6;">{{ (infos.address ==
                  item.address ? '(' + $t('legion.ranks.myOwn') + ')' : '') }}</span></td>
                <td>{{ Number(item.integral).toFixed(2) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="getMdsBox" :show-close="true" :center="true" :modal="false"
      :title="$t('legion.receive') + 'MDS'" width="378px">
      <div class="flex ai-center jc-center">
        <span class="dialog-text">{{ $t('legion.canReceive') }}</span>
        <span class="dialog-text num m-l-5 m-r-5">{{Number(legionData.availableIntegral).toFixed(2)}}</span>
        <span class="dialog-text">MDS</span>
      </div>
      <!-- <div class="flex jc-between ai-center">
        <div style="color:#FFFFFF;">{{ $t('legion.getMdsBox.amount') }}</div>
        <div style="color:#999999;">{{ $t('legion.getMdsBox.availablePoints') }}: {{ Number(legionData.availableIntegral).toFixed(2) || 0 }}</div>
      </div>       -->
      <!-- <div class="m-t-10 m-b-20 flex jc-between ai-center" style="background-color:#111111;border-radius: 5px;">
        <el-input autocomplete="off" style="width:80%" :placeholder="$t('legion.getMdsBox.amountPlaceholder')" v-model="txtGetMdsQty" oninput="value=value.replace(/[^\d]/g,'')" />
        <div class="m-r-15 cursor" style="color:#37B497" @click="txtGetMdsQty = Number(legionData.availableIntegral).toFixed(2)">{{ $t('legion.getMdsBox.btnAll') }}</div>
      </div> -->
      <el-button class="dialog-btn" type="primary" @click="receiveMds" :loading="loadingGetMds">
        {{ $t('legion.getMdsBox.btnConfirm') }}
      </el-button>
      <p class="dialog-rules d-flex jc-center">{{ $t('legion.rules1') }}</p>
      <p class="dialog-rules d-flex jc-center">{{ $t('legion.rules2', { finalTime: legionData.time ?legionData.time :'--' }) }}</p>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      infoDisabled: false,
      getMdsBox: false,
      txtGetMdsQty: '',
      loadingGetMds: false,
      legionData: {},
      ruleData: [
        {
          level: 2,
          condition: this.$t('legion.integralRules4'),
          integral: this.$t('legion.integralRules5')
        },
        {
          level: 3,
          condition: this.$t('legion.integralRules7'),
          integral: this.$t('legion.integralRules8')
        },
        {
          level: 4,
          condition: this.$t('legion.integralRules10'),
          integral: this.$t('legion.integralRules11')
        }
      ],
      rankList: []
    };
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
      sets: (state) => state.sets,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.pageFun();
      this.pageFun1();
    }, 200);
  },
  methods: {
    pageFun1() {
      // this.loading = true;
      this.$axios
        .get(
          this.$api.integralRanks,
          null,
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          this.rankList = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    levelName(lv) {
      switch (lv) {
        case 1: return this.$t('legion.levelName.1')
        case 2: return this.$t('legion.levelName.2')
        case 3: return this.$t('legion.levelName.3')
        case 4: return this.$t('legion.levelName.4')
      }
    },
    levelStar(lv) {
      switch (lv) {
        case 1: return '☆'
        case 2: return '★'
        case 3: return '★★'
        case 4: return '★★★'
      }
    },
    pageFun() {
      this.loading = true;
      this.$axios
        .get(
          this.$api.getUserTeam,
          null,
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          this.legionData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    receiveMds() {
      this.loadingGetMds = true;
      this.$axios
        .post(
          this.$api.receiveMds,
          { },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          if (res.code == 200) {
            this.pageFun();
            this.$message.success(this.$t('legion.getMdsBox.success'));
            this.getMdsBox = false
          }
          this.loadingGetMds = false;
        })
        .catch((err) => {
          this.loadingGetMds = false;
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.dialog {
  &-text {
    color: #FFFFFF;
    font-size: 16px;

    &.num {
      font-size: 21px;
    }
  }

  &-btn {
    width: 100%;
    margin-bottom: 20px;
  }

  &-rules {
    color: #999999;
    font-size: 14px;
    line-height: 24px;
  }
}


.black-box {
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;
  padding: 14px;
  height: 78px;

  &-received {
    color: #FFFFFF;
    font-size: 14px;
    margin-left: 20px;
  }
}

table.black-table {
  width: 100%;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;

  td {
    padding: 14px;
    border: 1px solid #4c5158;
    height: 86px;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    width: 30%;

    &:nth-child(2) {
      width: 40%;
    }
  }
}


.blue-btn {
  height: 28px;
  padding: 15px 15px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #09AFE9;
  color: #09AFE9;
  cursor: pointer;
}

.btn {
  height: 30px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 14px;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}

.btn-green {
  background: #317f6e;
  border: none;
  font-weight: bold;
}

.responsive-box {
  display: flex;
  justify-content: space-between;
  &.jcs {
    justify-content: start;
  }

  .responsive-box-child {
    width: 50%;
  }
}

.hide-in-mobile {
  display: block;
}

.invite-notes {
  display: none;
  line-height: 22px;
}

@media screen and (max-width: 768px) {

  .responsive-box {
    display: flex;
    flex-direction: column;

    .responsive-box-child {
      width: 100%;
    }
  }

  .hide-in-mobile {
    display: none;
  }

  .invite-notes {
    display: block;
  }


}</style>